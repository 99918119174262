import { Injectable } from '@angular/core';
import { StoreModel } from '../models/store.model';
import { AttributeModel, AttributeStoreModel } from '../models/attribute.model';
import { MainService } from './main.service';
import { UsersService } from './users.service';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(
    private main: MainService,
    private usrService : UsersService
  ) { }
  areaName : string = '/stores'


  allAtributes : string[] = [];
  attributes = [];
  sucursales = [];
  viewData : any = [];
  relations = [];
  dataSourceShared!: MatTableDataSource<any>;


  newAtribute(newAtribute : any){
    return this.main.postRequest(newAtribute, this.areaName + "/attribute/create");
  }

  editAtribute(editAtribute : any){
    return this.main.postRequest(editAtribute, this.areaName + "/attribute/edit");
  }


  newValue(newAtribute : any){
    return this.main.postRequest(newAtribute, this.areaName + "/setatribute");
  }

  setMasiveValue(newAtribute : any){
    return this.main.postRequest(newAtribute, this.areaName + "/setatributemasive");
  }

  getAtributos(){
    return this.main.getRequest(this.areaName + "/attributes/list");
  }

  getStoresView(){
    return this.main.getRequest(this.areaName + "/list");
  }

  getStoresSlimView(){
    return this.main.getRequest(this.areaName + "/listslim");
  }

  getStoresAttr(id_suc : number){
    return this.main.getRequest(this.areaName + "/suc_attributes/" + id_suc);
  }

  getCutoffs(){
    return this.main.getRequest(this.areaName + "/cutoffs/list");
  }
  
  newCutoff(newCutoff : any){
    return this.main.postRequest(newCutoff, this.areaName + "/cutoffs/create");
  }

  editCutoff(editCutoff : any){
    return this.main.postRequest(editCutoff, this.areaName + "/cutoffs/edit");
  }

  getCuttOffData(file_id : any){
    return this.main.postRequest({ file_id : file_id }, this.areaName + "/cutoffs/getFileData");
  }

  uploadStoresMasiveFile(form : FormData){
    return this.main.postRequestFormData(form, this.areaName + "/uploadmassive");
  }

  uploadStoresCreateMasiveFile(form : FormData){
    return this.main.postRequestFormData(form, this.areaName + "/uploadmassive/sucs");
  }

  acceptDenyAuth(acceptPl : any){
    return this.main.postRequest(acceptPl, this.areaName + "/approvechange");
  }

  newSucursal(sucursal : any){
    return this.main.postRequest(sucursal, this.areaName + "/store/create");
  }

  newZone(sucursal : any){
    return this.main.postRequest(sucursal, this.areaName + "/zone/create");
  }

  deleteSucursal(sucursalid : number){
    return this.main.postRequest({ id_sucursal :sucursalid}, this.areaName + "/store/delete");
  }

  updateOrder(atributos : any[]){
    return this.main.postRequest({ atributos :atributos}, this.areaName + "/order/reorder");
  }


  hasPermissions(key : string, buho : boolean|any, client : boolean|any):  boolean{
    return this.usrService.getUser().id_rol == environment.role_admin_id || (buho==null&&this.isBuhoUsr()) || (client==null&&this.isClientUsr()) || (((buho?this.isBuhoUsr():false) || (client?this.isClientUsr():false)) && (this.usrService.getUser().permissions.includes(key)));
  }

  isBuhoUsr(): boolean{
    return  this.usrService.getUser().id_rol == environment.role_buho ||  this.usrService.getUser().id_rol == environment.role_admin_id;
  } 
  isClientUsr(): boolean{
    return  this.usrService.getUser().id_rol == environment.role_client;
  }

  getStoresList() {
    let storeList: Array<StoreModel> = [];
    storeList =
    [
      {
        id: "1",
        zone: "MEXICO CENTRO",
        store: "F A MEXICO CENTRO, APATLACO",
        sub: "1674",
        farmID: "MXAP",
        city: "DF CENTRO",
        status: "ABIERTA",
        dateOpen: "05/05/2020",
        format: "TRADICIONAL"
      },
      {
        id: "2",
        zone: "MEXICO CENTRO",
        store: "F A MEXICO CENTRO, MARIO PANI",
        sub: "1829",
        farmID: "MXMP",
        city: "DF CENTRO",
        status: "CERRADA",
        dateOpen: "06/05/2020",
        format: "PLUS"
      },
      {
        id: "3",
        zone: "MEXICO CENTRO",
        store: "F A MEXICO CENTRO, XOLA",
        sub: "103",
        farmID: "MXXO",
        city: "DF CENTRO",
        status: "ABIERTA",
        dateOpen: "07/05/2020",
        format: "TRADICIONAL"
      },
      {
        id: "4",
        zone: "MONTERREY ORIENTE",
        store: "F A MONTERREY OTE, HOSPITAL",
        sub: "515",
        farmID: "MTHO",
        city: "MONTERREY ORIENTE",
        status: "ABIERTA",
        dateOpen: "08/05/2020",
        format: "TRADICIONAL"
      },
      {
        id: "5",
        zone: "PUEBLA",
        store: "F A PUEBLA NORTE, LA PAZ",
        sub: "115",
        farmID: "PELP",
        city: "PUEBLA",
        status: "ABIERTA",
        dateOpen: "09/05/2020",
        format: "DERMA"
      },
      {
        id: "6",
        zone: "MEXICO CENTRO",
        store: "F A MEXICO CENTRO, REVOLUCION",
        sub: "120",
        farmID: "MXRE",
        city: "DF CENTRO",
        status: "ABIERTA",
        dateOpen: "10/05/2020",
        format: "TRADICIONAL"
      }
    ]
    return storeList;
  }

  getAttributesList() {
    let attributeList: Array<AttributeModel> = [];
    attributeList =
    [
      {
        id: "1",
        name: "Botadero",
        type: "SI / NO",
        values: "SI|NO",
        edit: false,
        status: "",
        createdDate: "05/05/2020"
      },
      {
        id: "2",
        name: "Espacio Exhibidores",
        type: "Rango cantidad",
        values: "1-3",
        edit: false,
        status: "",
        createdDate: "05/05/2020"
      },
      {
        id: "3",
        name: "Cant. Unibanner 1",
        type: "Rango cantidad",
        values: "1-3",
        edit: false,
        status: "",
        createdDate: "05/05/2020"
      },
      {
        id: "4",
        name: "Medidas Unibanner 1",
        type: "Lista de Textos",
        values: "4 x 40|4 x 50|4 x 60",
        edit: false,
        status: "",
        createdDate: "05/05/2020"
      },
      {
        id: "5",
        name: "Refricalco",
        type: "SI / NO",
        values: "SI|NO",
        edit: true,
        status: "",
        createdDate: "05/05/2020"
      },
      {
        id: "6",
        name: "Protector",
        type: "SI / NO",
        values: "SI|NO",
        edit: true,
        status: "",
        createdDate: "05/05/2020"
      }
    ]
    return attributeList;
  }

  getAttributesStoreList() {
    let storeList: Array<AttributeStoreModel> = [];
    storeList =
    [
      {
        id: "1",
        store: "F A MEXICO CENTRO, APATLACO",
        zone: "MEXICO CENTRO",
        farmID: "MXAP",
        city: "DF CENTRO",
        format: "TRADICIONAL",
        value: "NO"
      },
      {
        id: "2",
        store: "F A MEXICO CENTRO, MARIO PANI",
        zone: "MEXICO CENTRO",
        farmID: "MXMP",
        city: "DF CENTRO",
        format: "PLUS",
        value: "NO"
      },
      {
        id: "3",
        store: "F A MEXICO CENTRO, XOLA",
        zone: "MEXICO CENTRO",
        farmID: "MXXO",
        city: "DF CENTRO",
        format: "TRADICIONAL",
        value: "NO"
      },
      {
        id: "4",
        store: "F A MONTERREY OTE, HOSPITAL",
        zone: "MONTERREY ORIENTE",
        farmID: "MTHO",
        city: "MONTERREY ORIENTE",
        format: "TRADICIONAL",
        value: "NO"
      },
      {
        id: "5",
        store: "F A PUEBLA NORTE, LA PAZ",
        zone: "PUEBLA",
        farmID: "PELP",
        city: "PUEBLA",
        format: "DERMA",
        value: "SI"
      },
      {
        id: "6",
        store: "F A MEXICO CENTRO, REVOLUCION",
        zone: "MEXICO CENTRO",
        farmID: "MXRE",
        city: "DF CENTRO",
        format: "TRADICIONAL",
        value: "SI"
      }
    ]
    return storeList;
  }
}
