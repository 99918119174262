<div class="modal-header">
    <h4 class="modal-title" id="mondalAddCampaignTitle"><mat-icon color="accent" class="align-middle">info</mat-icon> Editar Atributos de Sucursal <strong>{{ dataRow.Nombre }}</strong></h4>    
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>

    <div class="modal-body">

        <div class="row g-2">

            <div class="col mb-0">
                <div class="table-responsive text-nowrap">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>Atributo</th>
                                <th>Valor actual</th>
                                <th>Valor nuevo</th>
                                <th>Fecha de aplicación</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody class="table-border-bottom-0">
                          <ng-container *ngFor="let att of attributes">
                            <tr *ngIf="att.editable == 1">
                                <td>
                                    {{ att.name }}
                                </td>
                                <td>
                                    <strong>{{ dataRow[att.name] }}</strong>
                                </td>
                                <td>

                                    <mat-form-field [hidden]="att.type_id != 4">
                                        <mat-label><mat-icon>edit</mat-icon> Editar</mat-label>
                                        <input matInput #input4  type="number" placeholder="Valor de {{getRanges(att.select_data)[0]}} a {{getRanges(att.select_data)[1]}}" min="{{getRanges(att.select_data)[0]}}" max="{{getRanges(att.select_data)[1]}}">
                                    </mat-form-field>

                                    <mat-form-field [hidden]="att.type_id != 6">
                                        <mat-label><mat-icon>edit</mat-icon> Editar</mat-label>
                                        <input matInput #input6 type="number" > 
                                    </mat-form-field> 
                                  
                                    <mat-form-field [hidden]="att.type_id != 5">
                                        <mat-label><mat-icon>edit</mat-icon> Editar</mat-label>
                                        <input matInput #input5 > 
                                    </mat-form-field>

                                   <mat-form-field name="atributo" [hidden]="att.type_id != 3">
                                       <mat-label>Editar</mat-label>
                                       <mat-select name="multi" #input3>
                                            <ng-container *ngIf="att.type_id == 3">
                                                <mat-option  *ngFor="let multi of converToObject(att.select_data)"  [value]="multi">{{multi}}</mat-option>
                                            </ng-container>  
                                       </mat-select>
                                   </mat-form-field>
                                 
                                   <mat-form-field name="atributo" [hidden]="att.type_id != 1">
                                    <mat-label>Editar</mat-label>
                                    <mat-select name="multi" #input1>
                                        <mat-option [value]="'SI'">SI</mat-option>
                                        <mat-option [value]="'NO'">NO</mat-option>
                                    </mat-select>
                                    </mat-form-field>

                                    <mat-form-field name="atributo" [hidden]="att.type_id != 2">
                                        <mat-label>Editar</mat-label>
                                        <input name="pickerInput" matInput [matDatepicker]="picker2" #input2 placeholder="DD / MM / YYYY" >
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker [startAt]="startDate" #picker2></mat-datepicker>
                                    </mat-form-field>

                                </td>
                                <td>
                                    <mat-form-field>
                                        <mat-label>Fecha de aplicación</mat-label>
                                        <input name="pickerInput" matInput [matDatepicker]="picker1" [min]="minDate" value="{{obtenerFechaFormateada()}}" #inputRef placeholder="DD / MM / YYYY" >
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker [startAt]="startDate" #picker1></mat-datepicker>
                                    </mat-form-field>
                                </td>
                                <td>

                                    <button *ngIf="att.type_id == 1" type="button" class="btn btn-sm btn-success" (click)="save(att.id, input1.value, inputRef.value)">Guardar <mat-icon class="align-middle">save</mat-icon></button>
                                    <button *ngIf="att.type_id == 2" type="button" class="btn btn-sm btn-successr" (click)="save(att.id, input2.value, inputRef.value)">Guardar <mat-icon class="align-middle">save</mat-icon></button>
                                    <button *ngIf="att.type_id == 3" type="button" class="btn btn-sm btn-success" (click)="save(att.id, input3.value, inputRef.value)">Guardar <mat-icon class="align-middle">save</mat-icon></button>
                                    <button *ngIf="att.type_id == 4"  type="button" class="btn btn-sm btn-success" (click)="save(att.id, input4.value, inputRef.value)">Guardar <mat-icon class="align-middle">save</mat-icon></button>
                                    <button *ngIf="att.type_id == 5" type="button" class="btn btn-sm btn-success" (click)="save(att.id, input5.value, inputRef.value)">Guardar <mat-icon class="align-middle">save</mat-icon></button>
                                    <button *ngIf="att.type_id == 6" type="button" class="btn btn-sm btn-success" (click)="save(att.id, input6.value, inputRef.value)">Guardar <mat-icon class="align-middle">save</mat-icon></button>

                                    <button *ngIf="att.type_id == 1" type="button" class="d-none ComprobateAttr" (click)="comprobate(att.id, input1.value, inputRef.value)">Guardar <mat-icon class="align-middle">save</mat-icon></button>
                                    <button *ngIf="att.type_id == 2" type="button" class="d-none ComprobateAttr" (click)="comprobate(att.id, input2.value, inputRef.value)">Guardar <mat-icon class="align-middle">save</mat-icon></button>
                                    <button *ngIf="att.type_id == 3" type="button" class="d-none ComprobateAttr" (click)="comprobate(att.id, input3.value, inputRef.value)">Guardar <mat-icon class="align-middle">save</mat-icon></button>
                                    <button *ngIf="att.type_id == 4" type="button" class="d-none ComprobateAttr" (click)="comprobate(att.id, input4.value, inputRef.value)">Guardar <mat-icon class="align-middle">save</mat-icon></button>
                                    <button *ngIf="att.type_id == 5" type="button" class="d-none ComprobateAttr" (click)="comprobate(att.id, input5.value, inputRef.value)">Guardar <mat-icon class="align-middle">save</mat-icon></button>
                                    <button *ngIf="att.type_id == 6" type="button" class="d-none ComprobateAttr" (click)="comprobate(att.id, input6.value, inputRef.value)">Guardar <mat-icon class="align-middle">save</mat-icon></button>

                                    <button *ngIf="att.type_id == 1" type="button" class="d-none btnSaveAttr" (click)="savePart(att.id, input1.value, inputRef.value)">Guardar <mat-icon class="align-middle">save</mat-icon></button>
                                    <button *ngIf="att.type_id == 2" type="button" class="d-none btnSaveAttr" (click)="savePart(att.id, input2.value, inputRef.value)">Guardar <mat-icon class="align-middle">save</mat-icon></button>
                                    <button *ngIf="att.type_id == 3" type="button" class="d-none btnSaveAttr" (click)="savePart(att.id, input3.value, inputRef.value)">Guardar <mat-icon class="align-middle">save</mat-icon></button>
                                    <button *ngIf="att.type_id == 4" type="button" class="d-none btnSaveAttr" (click)="savePart(att.id, input4.value, inputRef.value)">Guardar <mat-icon class="align-middle">save</mat-icon></button>
                                    <button *ngIf="att.type_id == 5" type="button" class="d-none btnSaveAttr" (click)="savePart(att.id, input5.value, inputRef.value)">Guardar <mat-icon class="align-middle">save</mat-icon></button>
                                    <button *ngIf="att.type_id == 6" type="button" class="d-none btnSaveAttr" (click)="savePart(att.id, input6.value, inputRef.value)">Guardar <mat-icon class="align-middle">save</mat-icon></button>
                                </td>
                            </tr>
                        </ng-container>
                            <!--<tr>
                                <td>
                                    Refricalco
                                </td>
                                <td>
                                    <strong>NO</strong>
                                </td>
                                <td>
                                    <mat-form-field>
                                        <mat-label><mat-icon>edit</mat-icon> Editar</mat-label>
                                        <input matInput #input>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field>
                                        <mat-label>Fecha de aplicación</mat-label>
                                        <input name="pickerInput" matInput [matDatepicker]="picker1" placeholder="DD / MM / YYYY" >
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <button type="button" class="btn btn-sm btn-success">Guardar <mat-icon class="align-middle">save</mat-icon></button>
                                </td>
                            </tr>-->
                            <!--<tr>
                                <td>
                                    Espacio Exhibidores
                                </td>
                                <td>
                                    <strong>1</strong>
                                </td>
                                <td>
                                    <mat-form-field>
                                        <mat-label><mat-icon>edit</mat-icon> Editar</mat-label>
                                        <input matInput #input>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field>
                                        <mat-label>Fecha de aplicación</mat-label>
                                        <input name="pickerInput" matInput [matDatepicker]="picker" placeholder="DD / MM / YYYY" >
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <button type="button" class="btn btn-sm btn-success">Guardar <mat-icon class="align-middle">save</mat-icon></button>
                                </td>
                            </tr>-->
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer modal-foot-stick">
        <button type="button" class="btn btn-success " (click)="comprobateAll()">Guardar Cambios <mat-icon class="align-middle">save</mat-icon></button>
    </div>
    <!--<div class="col offset-sm-10">
        
    </div>-->
</mat-dialog-content>