<div class="content-wrapped">
    <!-- Content -->
    <div class="container-xxl flex-grow-1 container-p-y">
        <h4 class="fw-bold py-3 mb-4 text-primary">
            <span>Tickets de campaña</span>
            <br>
            <!--<h5>
                <mat-icon class="text-info align-middle">filter_list</mat-icon> Filtro
            </h5>-->
            <div class="filterContainer">
                <mat-accordion>
                    <mat-expansion-panel [expanded]="expanded_filters">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon class="text-info align-middle">filter_list</mat-icon> Filtro
                        </mat-panel-title>
                        <mat-panel-description>
                          Filtrado de Tickets de campaña
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <div>
                        <form>                    
                            <div class="row">
                             <div *ngIf="companias.length>1 && isBuhoUser()" class="col-md-3 col-sm-12">
                                 <mat-form-field class="w-100" appearance="outline">
                                     <mat-label>Compania</mat-label>
                                     <mat-select name="company" [(ngModel)]="selectedcompany" (selectionChange)="loadCampaigns(); loadZones();" required>
                                        <mat-option *ngFor="let p of companias" [value]="p.id" value="option">{{ p.name }}</mat-option>
                                      </mat-select>
                                      <mat-error>Requerido</mat-error>
                                   </mat-form-field>
                             </div>

                             <div class="col-md-3 col-sm-12">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Campaña</mat-label>
                                    <mat-select name="camp" [(ngModel)]="selectedCamp" required>
                                        <mat-option *ngFor="let camp of campaignsData.all;"  [value]="camp.id" value="option">{{ camp.name }}</mat-option>    
                                    </mat-select>
                                    <mat-error>Requerido</mat-error>
                                  </mat-form-field>
                             </div>

                              <div class="col-md-3 col-sm-12">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Zonas</mat-label>
                                    <mat-select  name="zones" [(ngModel)]="selectedzone" >
                                        <mat-option [value]="null">-- Ninguna --</mat-option>
                                        <mat-option *ngFor="let zone of zones;"  [value]="zone.id" value="option">{{ zone.nombre }}</mat-option>
                                    </mat-select>
                                    <mat-error>Requerido</mat-error>
                                  </mat-form-field>
                              </div>

                              <div class="col-md-3 col-sm-12">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Estatus Encuesta</mat-label>
                                    <mat-select name="surv" [(ngModel)]="selectedStsSurv" >
                                      <mat-option [value]="null">-- Ninguno --</mat-option>
                                      <mat-option *ngFor="let stsS of statusSurv;" [value]="stsS.id"><span [ngClass]="stsS.class">{{ stsS.nombre }}</span></mat-option>
                                    </mat-select>
                                    <mat-error>Requerido</mat-error>
                                  </mat-form-field>
                              </div>

                              
                              <div class="col-md-3 col-sm-12">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Estatus Seguimiento</mat-label>
                                    <mat-select name="strack" [(ngModel)]="selectedStsTrack" >
                                      <mat-option [value]="null">-- Ninguno --</mat-option>
                                      <mat-option *ngFor="let sts of statusTracking;" [value]="sts.id"> <span [ngClass]="sts.class">{{ sts.nombre }}</span></mat-option>
                                    </mat-select>
                                    <mat-error>Requerido</mat-error>
                                  </mat-form-field>
                              </div>
                              <div class="col-md-3 col-sm-12">
                                <button (click)="search()" mat-flat-button class="bg-success text-white" style="top: 12px; margin-bottom: 25px;" form="userForm" type="submit">Buscar <mat-icon>search</mat-icon></button>
                              </div>
                            </div>
                          </form>
                      </div>

                    </mat-expansion-panel>
                  </mat-accordion>
            </div>

        </h4>

        <!-- Card on time strategies -->
        <div class="row" [hidden]="!(datatracking.length > 0)">
            <div class="col-md-12">
                <div class="card mb-4">
                    <h5 class="card-header">
                        <mat-icon class="text-info align-middle">stop</mat-icon> Seguimiento - {{ campanaName }}
                    </h5>

                    <div class="card-body">
                        <div class="overflow-auto w-200">
                            <mat-form-field>
                                <mat-label>
                                    <mat-icon>search</mat-icon> Buscar</mat-label>
                                <input matInput (keyup)="applyFilter($event)" placeholder="Sobre burbuja" #input>
                            </mat-form-field>
                        </div>
                            <div class="mat-elevation-z0 table-responsive text-nowrap">
                                <table mat-table [dataSource]="dataSource" matSort class="w-100">
                        
                                    <ng-container matColumnDef="zona">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Zona </th>
                                        <td mat-cell *matCellDef="let row"> {{row.zona}} </td>
                                    </ng-container>
                        
                                    <ng-container matColumnDef="sucursal">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Sucursal </th>
                                        <td mat-cell *matCellDef="let row"> {{row.sucursal}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="alertas">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Alertas </th>
                                        <td mat-cell *matCellDef="let row"> {{row.alertas}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="st_encuesta">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Estatus Encuesta </th>
                                        <td mat-cell *matCellDef="let row"> <span [ngClass]="getStatusSurvDat(row.st_encuesta).class">{{getStatusSurvDat(row.st_encuesta).nombre}}</span> </td>
                                    </ng-container>

                                    <ng-container matColumnDef="st_seguimiento">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Estatus Seguimiento </th>
                                        <td mat-cell *matCellDef="let row"> 
                                            <span *ngIf="row.st_encuesta !== 1" [ngClass]="getStatusTrackingDat(row.st_seguimiento).class">{{getStatusTrackingDat(row.st_seguimiento).nombre}}</span> 
                                            <span *ngIf="row.st_encuesta == 1" [ngClass]="'badge badge-warning'">Pendiente</span> 
                                        </td>
                                    </ng-container>


                                    <ng-container matColumnDef="Acciones">
                                        <th mat-header-cell *matHeaderCellDef class="text-center"> Acciones </th>
                                        <td mat-cell *matCellDef="let row" class="text-center">
                                            <button *ngIf="row.st_seguimiento != 5 && row.st_encuesta != 1" mat-icon-button matTooltip="Ver tickets" [matTooltipPosition]="'above'" color="accent"  [routerLink]="'/admin/ticket/'+row.id_compania + '/' + row.id_campana  + '/' + row.id_sucursal">
                                                <mat-icon>remove_red_eye</mat-icon>
                                            </button>
                                            <button mat-icon-button matTooltip="Ir a encuesta" [matTooltipPosition]="'above'" color="accent" (click)="gotoSurvey(row.id_sucursal)">
                                                <mat-icon>link</mat-icon>
                                            </button>
                                        </td>
                                    </ng-container>
                        
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        
                                    <tr class="mat-row" *matNoDataRow>
                                        <td class="mat-cell" colspan="6">No existe información que coincida con "{{input.value}}"</td>
                                    </tr>
                                </table>
                        
                                <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                            </div>        
                    </div>
                </div>
            </div>
        </div>
        <!-- Card active campaigns strategies-->

    </div>
</div>