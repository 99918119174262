import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { MainService } from 'src/app/services/main.service';
import { SurveysService } from 'src/app/services/surveys.service';
import { surveyDistributionModel } from 'src/app/models/surveys.model';
import { NgxQrcodeElementTypes } from '@techiediaries/ngx-qrcode';
import { SurveyMaterialFileComponent } from '../../shared/dialogs/survey-material-file/survey-material-file.component';
import { SurveyImageSliderComponent } from '../../shared/dialogs/survey-image-slider/survey-image-slider.component';
import { jsPDF } from "jspdf";

import Swal from 'sweetalert2';
import { SurveyMaterialEditComponent } from '../../shared/dialogs/survey-material-edit/survey-material-edit.component';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-surveys-distribution',
  templateUrl: './surveys-distribution.component.html',
  styleUrls: ['./surveys-distribution.component.scss']
})
export class SurveysDistributionComponent implements OnInit {
  //Material related vars 
  form!: FormGroup;
  displayedColumns: string[] = ['id_zona', 'id_sucursal', 'estafeta', 'consecutivo', 'nombre_mat', 'Acciones'];
  displayedMatColumns: string[] = ['id', 'name', 'description', 'cantidad_pza', 'Acciones'];
  dataSource!: MatTableDataSource<surveyDistributionModel>;
  @ViewChild("paginator") paginator!: MatPaginator;
  @ViewChild("sort") sort!: MatSort;
  @ViewChild("paginator2") paginator2!: MatPaginator;
  @ViewChild("sort2") sort2!: MatSort;

  isClientUsr: boolean;
  isBuhoUsr: boolean;
  private campaingId : number = 0;
  zonas : any = [];
  sucursales : any = [];
  surveyData : any = [];
  campana : any = [];
  campanaHistory : any = [];
  hostName : string = "";
  elementType = NgxQrcodeElementTypes.URL
  generatedUrls: any[] = [];
  imagesref: any[] = [];
  materiales_campana!: MatTableDataSource<any>;
  constructor(
    private surveyS : SurveysService,
    private main : MainService,
    private _params: ActivatedRoute,
    private _titleService: Title,
    private _router : Router,
    private matDialog: MatDialog,
    private campaignService : CampaignsService,
    ) {
      this._params.params.subscribe(params => {
        if(params.id){
          this.campaingId = params.id;
          this.loadEncuestaData();
          this.getMaterials();
        }else{
          this._router.navigateByUrl('/admin/dashboard');
        } 
      });
      this.isClientUsr = this.campaignService.isClientUsr();
      this.isBuhoUsr = this.campaignService.isBuhoUsr();

      this.hostName = window.location.protocol + '//' + window.location.host + "/surveys/";
      console.log(this.hostName);
     }

  ngOnInit(): void {
   // this.loadEncuestaData()
  }

  loadEncuestaData(){
    this.main.ShowLoading();
    this.surveyS.getFilters(this.campaingId).subscribe((filter:any)=>{
      if(filter.message = 'Success'){
      this.zonas = filter.data.zonas;
      this.sucursales = filter.data.sucursales;
      console.log(filter);
      this.surveyS.getSurvey(this.campaingId, 0).subscribe((result:any)=>{
        if(result.message = 'Success'){
          this.main.HideLoading();
          console.log(result);
          this.campana = result.data.campana;
          this._titleService.setTitle("Buho Nest - Encuestas Distribución - " + this.campana.name);
          this.campanaHistory = result.data.history;
          this.surveyData = result.data.surveyData;
          this.dataSource = new MatTableDataSource(this.surveyData);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.imagesref = result.data.imagesref;
          this.getEncuestasGroupAndReduce();
          //this.loadStatus();
        }
       },
       (err:any)=>{
        console.log(err);
       });
      }
    });

   }

   hasImage(idfind : number){
    return this.imagesref.some(x=> x.id_material_campana == idfind);
   }

   getZonaName(zone_id:  number) : string {
    return this.zonas.filter((x:any)=>{ return x.id ===  zone_id })[0].nombre;
   }

   getSucursalName(suc_id:  number) : string {
    return this.sucursales.filter((x:any)=>{ return x.id ===  suc_id })[0].nombre_sucursal;
   }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) this.dataSource.paginator.firstPage();
  }

  applyMatFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.materiales_campana.filter = filterValue.trim().toLowerCase();
    if (this.materiales_campana.paginator) this.materiales_campana.paginator.firstPage();
  }

  encuestaSucursal : any[] = []

  getEncuestasGroupAndReduce(){
    let groupEnc = this.surveyData.reduce((x : any, y : any) => {
      (x[y.id_sucursal] = x[y.id_sucursal] || []).push(y);
      return x
    }, {});
    this.encuestaSucursal = [];
    Object.keys(groupEnc).forEach(key => {
      this.encuestaSucursal.push({ id_sucursal : groupEnc[key][0].id_sucursal, id_campana: groupEnc[key][0].id_campana, estafeta : groupEnc[key][0].estafeta, consecutivo : groupEnc[key][0].consecutivo })
    });
  }

  generatePDF(){
   // this.main.ShowLoading();
    Swal.fire({
      icon: 'info',
      title: 'Generando QRs...',
      text:'Esto puede tardar algunos minutos, favor de no cerrar o recargar la pagina.',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    this.encuestaSucursal = this.encuestaSucursal.sort((a, b) => a.consecutivo - b.consecutivo);
    this.surveyS.getEncuestasToken(this.encuestaSucursal).subscribe((result: any)=>{
      //this.main.HideLoading();
      if(result.message = 'Success'){
        this.generatedUrls = result.data.map((x:any)=>{ return {url : this.hostName + x.token, estafeta : x.estafeta } });
        //console.log(this.generatedUrls);
        let doc = new jsPDF({
          format: [102, 254] 
        });
        doc.setFontSize(6.2);
        //console.log('qrcode_' + (this.generatedUrls.length - 1));
        this.waitForElm('qrcode_'+  (this.generatedUrls.length - 1)).then(x=>{
          //this.main.ShowLoading();
          this.generatedUrls.forEach((guide, i)=>{
            const qrcode = document.getElementById('qrcode_'+  i);
            let imageData= this.getBase64Image(qrcode?.firstChild?.firstChild);
            doc.addImage(imageData, "JPG", 37.5, 8.5, 28, 28);
            doc.text("Scan me ["+ guide.estafeta +"]", 41.8, 36.7);  
            if(i != (this.generatedUrls.length - 1)){
              doc.addPage();
            }
           
          });
          doc.save('QR-Campana-'+ this.campana.name + "-" + this.getUniqueName() + '.pdf');
          //this.main.HideLoading();
            Swal.fire({
              icon: 'success',
              title: 'Archivo generado',
              text: 'El archivo se descargará en unos instantes'          
            }).then(() => {            
            });
          
        })
      }
    });
  }

  getConsecutivo(id_suc : number):number{
   return (this.encuestaSucursal.findIndex(x=> { return x.id_sucursal == id_suc }) + 1)
  }

  getBase64Image(img  : any) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx?.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png");
    return dataURL;
  }

  waitForElm(selector : any) {
    return new Promise(resolve => {
        if (document.getElementById(selector)) {
          console.log("element now exixst");
            return resolve(document.getElementById(selector));
        }

        const observer = new MutationObserver(mutations => {
            if (document.getElementById(selector)) {
                resolve(document.getElementById(selector));
                observer.disconnect();
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
}

private getUniqueName(): string {
  let d = new Date();
  let uniqueName = d.getDate().toString() + (d.getMonth() + 1).toString() + d.getFullYear().toString() + "_" + d.getHours().toString() + d.getMinutes().toString() + d.getSeconds().toString();
  return uniqueName
}

openDialogUploadFile(){
  this.matDialog.open(SurveyMaterialFileComponent, {
    panelClass: ['dialogs-main'],
    data: {
      campana : this.campana
    }
  }).afterClosed().subscribe(
    resp => {
      if(resp?.reload) {
        
      }
    }
  );
}

getMaterialImage(id:number, nombre : string){
  this.surveyS.getImagesMaterial(id).subscribe((result: any)=>{
    if(result.message = 'Success'){
      console.log(result);
      if(result.data.length !=0){
        this.matDialog.open(SurveyImageSliderComponent, {
          panelClass: ['dialog-img-lg'],
          data: {
            images : result.data,
            material_info : nombre,
            campana : this.campana
          }
        }).afterClosed().subscribe(
          resp => {
            if(resp?.reload) {
              
            }
          }
        );
      }else{
        Swal.fire({
          icon: 'warning',
          title: 'No hay imagenes',
          html: 'No se encontró ninguna imagen de este producto'
        });
      }
    }
  });
} 


getMaterials(){
  this.surveyS.getMaterialsCampana(this.campaingId).subscribe((result: any)=>{
    if(result.message = 'Success'){
      console.log(result);
      if(result.data.length !=0){
        this.materiales_campana = new MatTableDataSource(result.data.map((e:any)=>Object.assign({}, e, { 'edit': false})));
        this.materiales_campana.paginator = this.paginator2;
        this.materiales_campana.sort = this.sort2;
      }else{
        Swal.fire({
          icon: 'warning',
          title: 'No hay materiales cargados',
          html: 'No se encontró ningun material de esta campaña'
        });
      }
    }
  });
} 

  openEditMatCantidad(mat: any) {
    this.matDialog.open(SurveyMaterialEditComponent, {
      panelClass: ['dialogs-sm'],
      data: {
        material : mat
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp?.reload) {
          
        }
      }
    );
  }
}
