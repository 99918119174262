import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MainService } from 'src/app/services/main.service';
import { SurveysService } from 'src/app/services/surveys.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'surveys-comentary',
  templateUrl: './surveys-comentary.component.html',
  styleUrls: ['./surveys-comentary.component.scss']
})
export class SurveysComentaryComponent implements OnInit {

  constructor( private fb: FormBuilder,
    public dialogo: MatDialogRef<SurveysComentaryComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any, private surveyS : SurveysService, private main : MainService,) { }
  

  id_campana : number = 0;
  id_sucursal : number = 0;
  product : any = [];
  loading: boolean = false;
  ngOnInit(): void {
   this.getTicketsTypes()
   this.product = this.data.product;
   console.log(this.product);
  }


  confirmado(): void {
    this.dialogo.close(true);
  }

  Send(){
    this.loading = true;
    this.product.comentario = this.type + (this.product.comentario == ''? "" : " | " + this.product.comentario);
    console.log(this.product.comentario);
    this.confirmado();
  }

  type = "";
  ticketTypes : Array<any> = [];

  getTicketsTypes(){
    this.ticketTypes = environment.tipos_ticket;

    this.ticketTypes = this.ticketTypes.filter(x=> { return x.nombre != "Anulación" && x.nombre != "Solicitud adicional"});
  }

  select(evento : "any"){
    console.log(evento);
    this.type = evento;
  }

}
