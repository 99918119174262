import { Component, Inject, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { SurveysService } from 'src/app/services/surveys.service';
import { SurveyMaterialFileComponent } from '../survey-material-file/survey-material-file.component';
import { SurveyMageUploadFileComponent } from '../surveys-msg-upload-file/surveys-msg-upload-file.component';
import { SurveyImageMsgViewerComponent } from '../survey-msg-img-viewer/survey-msg-img-viewer.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-surveys-tickets-logs.',
  templateUrl: './surveys-tickets-logs.component.html',
  styleUrls: ['./surveys-tickets-logs.component.scss']
})
export class SurveysTicketsLogsComponent implements OnInit {

  survcamp: any;
  id_comp : number = 0;
  id_camp : number = 0;
  id_suc :  number = 0;
  camp_folder : string = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private campaignService : CampaignsService,
    private main : MainService,
    private dialogRef: MatDialogRef<SurveysTicketsLogsComponent>,
    private surveyS : SurveysService,
    private matDialog: MatDialog,
  ) {
    this.survcamp = this.data.item;
    this.id_comp = this.data.id_comp;
    this.id_camp = this.data.id_camp;
    this.id_suc = this.data.id_suc;
    this.camp_folder = this.data.camp_folder;
    this.loadData();
   }

  ngOnInit(): void {
  }

  mainLogoRoute = "../../assets/img/clients/";
  regLogs : any = [];
  message : string = "";
  
  loadData(){
    this.main.ShowLoading();
    this.surveyS.getCampaignSurveyLog(this.survcamp.id).subscribe(
      (result:any) => {
       this.main.HideLoading();
       this.regLogs = result.data;
      },
      (err:any) => {
        this.main.HideLoading();
        if(err.error.message != "No changelog were found in the request")
        {
          Swal.fire({
            icon: 'error',
            title: 'Error al obtener registros',
            text: 'Ocurrió un error al obtener los registros.'
          });
        }
      }
    );
  }

  reloadData(){
    this.surveyS.getCampaignSurveyLog(this.survcamp.id).subscribe(
      (result:any) => {
       this.regLogs = result.data;
      },
      (err:any) => {
        this.main.HideLoading();
        console.log(err);
        if(err.error.message != "No changelog were found in the request")
        {
          Swal.fire({
            icon: 'error',
            title: 'Error al obtener registros',
            text: 'Ocurrió un error al obtener los registros.'
          });
        }
      }
    );
  }

  saveNewMessage(){
    if(this.message!= ""){
      this.main.ShowLoading();
      this.surveyS.newCampaignSurveyMessage({"id_encuesta_campana": this.survcamp.id, "message": this.message, "id_compania": this.id_comp, "id_campana" : this.id_camp, "id_sucursal" : this.id_suc}).subscribe(
        (result:any) => {
          this.main.HideLoading();
          if(result.message == "Success") {
            this.message = "";
            this.reloadData();
          }
        },
        (err:any) => {
          this.main.HideLoading();
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
          });
        }
      );
    }
  }

  getUserCompanyOrRol(log : any){
      if(log.id_rol == 3 && log.company_name != null){
        return log.company_name;
      }else{
        return log.rol;
      }
  }

  getLogoCompany(logImage : string):string{
    return "url(" + this.mainLogoRoute + logImage + ")"
  }

  openDialogUploadFile(){
    this.matDialog.open(SurveyMageUploadFileComponent, {
      panelClass: ['dialogs-main'],
      data: {
        survcamp : this.survcamp,
        camp_folder :  this.camp_folder,
        id_comp : this.data.id_comp,
        id_camp : this.data.id_camp,
        id_suc : this.data.id_suc
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp?.reload) {
          this.reloadData();
        }
      }
    );
  }

  openDialogImageViewer(image : string, name: string){
    this.matDialog.open(SurveyImageMsgViewerComponent, {
      panelClass: ['dialogs-lg'],
      data: {
        imagedata : { name : name, image : image }
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp?.reload) {
          this.reloadData();
        }
      }
    );
  }

  private apiURL = environment.API_URL;
 
  imagedata : any;
  //imgroot = "https://lh3.google.com/u/0/d/";
  imgroot = this.apiURL + "/surveys/image/";

  getIMG(idimg : string){
    //return "https://lh3.google.com/u/0/d/" + idimg;
    return this.imgroot + idimg;
  }

}