<div style="z-index: 1300; position: relative;" *ngIf="loading"> 
    <div class="loading-backdrop" id="full-backdrop">
        <div class="loading-comp">
          <mat-progress-spinner [diameter]="140" mode="indeterminate"></mat-progress-spinner>
        </div>
    </div>
</div>
<div class="modal-header">
    <h4 class="modal-title" id="mondalAddCampaignTitle">Añadir Comentario de Alerta ({{product.nombre_mat}})</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">

  
    <div class="px-3 mt-3" id="sendEncuestaForm">
        <div class="row mt-2">
            <div class="col-sm-12">
                <mat-form-field appearance="outline" class="w-100" name="coso">
                    <mat-label>Tipo de Alerta</mat-label>
                    <mat-select [(ngModel)]="type" name="type" (selectionChange)="select($event.value)">
                        <mat-option *ngFor="let type of ticketTypes"  [value]="type.nombre">{{type.nombre}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Comentario / Detalle Alerta</mat-label>
                    <input matInput [type]="'text'" [(ngModel)]="product.comentario" name="comentario" maxlength="100" required>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button mat-button mat-dialog-close (click)="product.alerted = false; product.comentario = ''">Cancelar<mat-icon>close</mat-icon></button>
    <button mat-flat-button class="btn bg-success text-white" [disabled]="type == ''" type="submit" (click)="Send()">Aplicar Comentario </button>
</div>