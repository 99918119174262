import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';
import { MainService } from 'src/app/services/main.service';
import Swal from 'sweetalert2';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-stores-add',
  templateUrl: './stores-add.component.html',
  styleUrls: ['./stores-add.component.scss']
})
export class StoresAddComponent implements OnInit {


  formC!: FormGroup;
  zoneslist : any = [];


  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    private fb: FormBuilder,
    private userS: UsersService,
    private dialogRef: MatDialogRef<StoresAddComponent>,
    private matDialog: MatDialog,
    private StoreService : StoreService,
    private main : MainService
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.getZones();
    console.log(this.data.userSel);
  }

  @ViewChild('input') input!  : ElementRef<HTMLInputElement>;

  createForm() {
    this.formC = this.fb.group({
      nombre: ['', [ Validators.required, Validators.minLength(3)]],
      id_zona: ['', [Validators.required]],
      id_external: ['', [ Validators.required]],
    });
  }

  getZones(){
      this.main.ShowLoading();
      if(this.hasPermissions('suc_sup',null,true)){
        this.userS.getZones(1).subscribe(
          (result:any) => {
            if(result.message == "Success") {
              this.main.HideLoading();
              this.zoneslist = result.data;
            
            }
          },
          (err:any) => {
            this.main.HideLoading();
            console.log(err);
          }
        );
      }else{
        this.userS.getZonesByUser().subscribe(
          (result:any) => {
            if(result.message == "Success") {
              this.main.HideLoading();
              this.zoneslist = result.data;
           
            }
          },
          (err:any) => {
            this.main.HideLoading();
            console.log(err);
          }
        );
      }


  }


  ruSure() {
    if(this.formC.invalid) { return; }
    Swal.fire({
      title: '¿Estás seguro que deseas guardar el registro?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        this.save();
      }
    });
  }

  save() {
    Swal.fire({
      icon: 'info',
      title: 'Guardando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
   // this.formUser.value.id_compania = this.id_compania;
    this.StoreService.newSucursal(this.formC.value).subscribe(
      (result:any) => {
        if(result.message == "Success") {
          Swal.fire({
            icon: 'success',
            title: 'Guardado',
            html: 'Sucursal guardada de manera correcta'
          }).then(() => {            
            this.dialogRef.close({ reload: false });
          });
        }
      },
      (err:any) => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Error al guardar',
          text: 'Ocurrió un error al guardar el registro, por favor vuelva a intentarlo.'
        });
      }
    );
  }

  onZoneSelectionChange(nombre : string, id: number){
    this.ZoneAutoCompleteInput = nombre;
    this.formC.controls['id_zona'].setValue(id);
  }

  zonesAutoComplete : Array<any> = [];
  ZoneAutoCompleteInput : string = "";
  showNewButton : boolean = true;

  filterZones(evento : any){
    const inputValue = (evento.target as HTMLInputElement).value;
    this.ZoneAutoCompleteInput = inputValue;
    this.zonesAutoComplete = this.zoneslist.filter((option:any)=>
      option.nombre.toLowerCase().includes(inputValue.toLowerCase()) && inputValue != "");
  }

  
  hasPermissions(key : string, buho : boolean|any, client : boolean|any):  boolean {
    return this.StoreService.hasPermissions(key, buho, client);
  }

  saveZona(){
    if(this.ZoneAutoCompleteInput.length < 3){
      Swal.fire({
        icon: 'warning',
        title: 'Necesita un valor',
        html: 'Es necesario definir un nombre de Zona para guardar'
      }).then(() => {            
       
      });
    }else{
      if(this.zoneslist.some((option:any)=>
      option.nombre.toLowerCase() == this.ZoneAutoCompleteInput.toLowerCase())){
        Swal.fire({
          icon: 'warning',
          title: 'Ya existe zona',
          html: 'La zona ya existe'
        }).then(() => {            
         
        });
      }else{
        this.StoreService.newZone({ nombre : this.ZoneAutoCompleteInput.trim().toUpperCase()}).subscribe(
          (result:any) => {
            if(result.message == "Success") {
              Swal.fire({
                icon: 'success',
                title: 'Guardado',
                html: 'Zona guardada de manera correcta'
              }).then(() => {            
                this.getZones();
                this.zonesAutoComplete = this.zoneslist.filter((option:any)=>
                option.nombre.toLowerCase().includes(this.ZoneAutoCompleteInput.toLowerCase()));
              });
            }
          },
          (err:any) => {
            console.log(err);
            Swal.fire({
              icon: 'error',
              title: 'Error al guardar',
              text: 'Ocurrió un error al guardar el registro, por favor vuelva a intentarlo.'
            });
          }
        );
      }

    }
  }

}
