import { Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MainService } from 'src/app/services/main.service';
import { StoreService } from 'src/app/services/store.service';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { StoresAttributesPendingListReasonComponent } from '../stores-attributes-pending-list-reason/stores-attributes-pending-list-reason.component';

@Component({
  selector: 'app-stores-attributes-pending-list',
  templateUrl: './stores-attributes-pending-list.component.html',
  styleUrls: ['./stores-attributes-pending-list.component.scss']
})
export class StoresAttributesPendingListComponent implements OnInit {

  sucursales : any = [];
  pendientes : any = [];
  atributos : any = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private matDialog: MatDialog,
    private StoreService : StoreService,
    private main : MainService,
    private dialogRef: MatDialogRef<StoresAttributesPendingListComponent>,
  ) { 
    this.sucursales = this.data.sucursales;
    this.pendientes = this.data.pendientes;
    this.atributos = this.data.attributes;
  }

  ngOnInit(): void {
    console.log(this.data, this.pendientes);
  }

  openDialogAttrsPendingReason() {
    this.matDialog.open(StoresAttributesPendingListReasonComponent, {panelClass: 'dialogs-sm'}).afterClosed().subscribe(
      resp => {
      }
    );
  }

  findSucursal(id : number){
    try{
      return this.sucursales.filter((x:any)=> x.id == id)[0] 
    }catch(ex){
      return { Nombre: "Sucursal no encontrada" }
    }
   
  }

  findAtributo(id: number){
    try{
      return this.atributos.filter((x:any)=> x.id == id)[0] 
    }catch(ex){
      return { name: "Atriboto no encontrado" }
    }
  }

  autorize(id:number, autorized : any){
      let word = autorized == 1 ? "autorizar" : "denegar"

    Swal.fire({
      title: '¿Está seguro que desea '+ word +' este registro?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    })
      .then((willDelete) => {
        if (willDelete.isConfirmed) {
          if (willDelete.isConfirmed) {
            this.main.ShowLoading();
            this.StoreService.acceptDenyAuth({sucursal_atributo_id : id, autorized : autorized}).subscribe(
              (x: any) => {
                this.main.HideLoading();
                Swal.fire({
                  icon: 'success',
                  title: 'Operación exitosa',
                });
                this.dialogRef.close();
              },
              (err:any) => {
                this.main.HideLoading();
                Swal.fire({
                  icon: 'error',
                  title: 'Error al autorizar',
                  text: 'Ocurrió un error al autorizar el registro, por favor vuelva a intentarlo.'
                });
              }
            );    
          }
        } 
      });


    this.StoreService.acceptDenyAuth({sucursal_atributo_id : id, autorized : autorized})
  }

  hasPermissions(key : string, buho : boolean|any, client : boolean|any):  boolean {
    return this.StoreService.hasPermissions(key, buho, client);
  }

} 
